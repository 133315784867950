import { get, writable } from 'svelte/store';
import { makeQueryStore } from '@yakit/core/resource/QueryStore'
import { extend } from '@yakit/core/objectz';

/**
 * Customer Rest Api additions
 */
function TranQuery(){
  //const detailApi = TranApi({ky: paymentQuery.dataApi.ky})
  const tranQuery = makeQueryStore({ key: 'ar.tran' })
  tranQuery.selectedTotals = writable({})

  tranQuery.sumSelected = () => {
    const sums = {}
    const selectedIds = [...get(tranQuery.selectedIds)]
    const pageData = get(tranQuery.currentPage).data
    const selectedData = pageData?.filter((data) => selectedIds.includes(data.id)) || []
    for (const fieldName of tranQuery.sumFields) {

      sums[fieldName] = Number(selectedData.reduce((sum, obj) => sum + obj[fieldName], 0)).toFixed(2)
    }
    return sums
  }
  tranQuery.sumFields = ['amount', 'origAmount']
  
  let unsubs = [
    tranQuery.selectedIds.subscribe(async (selectedIdSet) => {
      const sums = tranQuery.sumSelected()
      tranQuery.selectedTotals.set(sums)
    }),
    // tranQuery.currentPage.subscribe(async page => {
    //   if(page.data) {
    //    await tranQuery.updateTotals()
    //   }
    // })
  ]
  tranQuery.unsubs.concat(unsubs)

  const clearRestrictSearch = () => {
    tranQuery.dataApi.restrictSearch = {}
  }

  const rpc = (name: string, data: object) => {
    return tranQuery.dataApi.rpc(name, data)
  }


  extend(tranQuery, {
    totals: tranQuery.totals,
    /** gets the total values */
    getTotals(){
      return get(tranQuery.totals)
    },

    clearRestrictSearch,
    async reload(){
      await tranQuery.query()
      await tranQuery.updateTotals()
    }
  })

  return tranQuery
}

export default TranQuery
